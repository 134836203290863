import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactMailIcon from '@mui/icons-material/ContactMail';

function Footer() {
  return (
    <Wrapper>
      <FooterTop>
        <Container>
          <Row>
            <AboutColumn>
              <AboutWidget>
                <img src="images/Jccnigltd.png" alt="logo" />
                <p>
                  At JesohChristy Construction Company, we’re dedicated to
                  quality infrastructure, and creating sustainable solutions for
                  communities and the future
                </p>
                <span>
                  Our expertise cuts across Project Management, Building
                  Construction and Consultancy. Partner with us Today!{' '}
                </span>
              </AboutWidget>
            </AboutColumn>
            <LinkColumn>
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <a href="/#about">About</a>
                </li>
                <li>
                  <a href="/#services">Services</a>
                </li>
                <li>
                  <a href="/#projects">Projects</a>
                </li>
                <li>
                  <a href="/#team">Our Team</a>
                </li>
                <li>
                  <a href="contact">Contact</a>
                </li>
              </ul>
            </LinkColumn>
            <AddressColumn>
              <h3>Office Address</h3>
              <p>
                No 23, Abiodun Bero, Abiodun Bero Estate Magodo Phase 1, Lagos.
              </p>
              <hr />
              <p className="phone">
                <ContactPhoneIcon /> (+234)8030551461, (+234)8179721431
              </p>
              <span className="phone">
                <ContactMailIcon /> info@jccnigltd.com
              </span>
              <hr />
              <ul>
                <li>
                  <a href="https://www.facebook.com/DoneDeck?mibextid=ZbWKwL">
                    <FacebookIcon />
                  </a>
                </li>
                <li>
                  <a href="twitter.com">
                    <XIcon />
                  </a>
                </li>
                <li>
                  <a href="linkedin.com">
                    <LinkedInIcon />
                  </a>
                </li>
                <li>
                  <a href="rssfeed.com">
                    <RssFeedIcon />
                  </a>
                </li>
                <li>
                  <a href="pinterest.com">
                    <PinterestIcon />
                  </a>
                </li>
              </ul>
            </AddressColumn>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Copyright>
          <p> © {new Date().getFullYear()} Phantom Dev. All Rights Reserved.</p>
        </Copyright>
      </FooterBottom>
    </Wrapper>
  );
}

export default Footer;

const Wrapper = styled.div`
  background-color: #252525;
  color: #afb0b2;
`;
const FooterTop = styled.div`
  padding: 80px 0;
`;

const AboutWidget = styled.div`
  img {
    margin-bottom: 25px;
    max-width: 90%;
    height: auto;
    vertical-align: middle;
    background: gray;
  }

  p {
    margin: 0 0 26px;
  }

  span {
    margin: 0 !important;
  }
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-right: -15px;
  margin-left: -15px;

  @media only screen and (max-width: 649px) {
    width: 100%;
    display: block;
  }

  @media screen and (max-width: 895px) and (min-width: 650px) {
    width: 100%;
    display: grid;
    grid-template-columns: 50% auto;
  }
`;

const AboutColumn = styled.div`
  /* display: flex; */
  width: 33.33%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media only screen and (max-width: 895px) {
    width: 100%;
  }
`;

const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 33.33%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media only screen and (max-width: 895px) {
    width: 100%;
    /* align-items: flex-start; */
  }

  @media only screen and (max-width: 649px) {
    align-items: flex-start;
  }

  h3 {
    margin-bottom: 40px;
    padding-bottom: 12px;
    color: #fff;
    font-size: 16px;
    position: relative;
    font-weight: 400;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      bottom: -14px;
      left: 0;
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 3px solid #fff;
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 18px;
      height: 3px;
      margin: 0 0 0;
      width: 70px;
      margin: 10px 0;
      background-color: #f57c00;
    }
  }

  ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;

    li {
      /* border-bottom: 1px solid #e1e1e1; */
      border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
      position: relative;
      padding: 8px 10px 8px 15px;
      margin-bottom: 0;
      line-height: 20px;

      &::before {
        content: '►';
        position: absolute;
        left: 0;
        top: 10px;
        display: block;
        font-family: 'FontAwesome';
        font-size: 14px;
        color: #afb0b2;
      }

      a {
        outline: 0 none;
        text-decoration: none;
        color: #afb0b2;
        display: block;
        border-bottom: 1px solid rgba(102, 102, 102, 0.5);
        position: relative;
        padding: 4px 0 4px 14px;
      }
    }
  }
`;

const AddressColumn = styled.div`
  width: 33.33%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media only screen and (max-width: 895px) {
    width: 100%;
  }

  .phone {
    display: flex;
    align-items: center;

    svg {
      margin-right: 20px;
      color: #fff;
    }
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 40px;
    padding-bottom: 12px;
    color: #fff;
    font-size: 16px;
    position: relative;
    font-weight: 400;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      bottom: -14px;
      left: 0;
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 3px solid #fff;
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 18px;
      height: 3px;
      margin: 0 0 0;
      width: 70px;
      margin: 10px 0;
      background-color: #f57c00;
    }
  }

  hr {
    margin: 20px 0;
  }

  ul {
    list-style: none;
    margin-top: 25px;
    display: flex;

    li {
      margin-right: 5px;

      a {
        font-size: 15px;
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 32px;
        text-align: center;
        color: #fff;
        transition: all 0.3s ease 0s;
        background: #383838;
        text-decoration: none !important;
        outline: none !important;
      }
    }
  }
`;

const FooterBottom = styled.div`
  padding: 20px 15px;
  border-top: 1px solid #3f361f;
  font-size: 14px;
`;

const Copyright = styled.div`
  p {
    margin: 0;
  }
`;
