import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useState } from 'react';

function About() {
  // let isCollapse = false;

  const [toggle1, setToggle1] = useState(true);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);

  const [visibility1, setVisibility1] = useState('block');
  const [visibility2, setVisibility2] = useState('none');
  const [visibility3, setVisibility3] = useState('none');

  const handleHistoryVisibility = (e) => {
    e.preventDefault();
    if (visibility1 === 'block') {
      setVisibility1('none');
    } else {
      setVisibility1('block');
      setVisibility2('none');
      setVisibility3('none');
    }
    setToggle1(!toggle1);
  };

  const handleMissionVisibility = (e) => {
    e.preventDefault();
    if (visibility2 === 'block') {
      setVisibility2('none');
    } else {
      setVisibility2('block');
      setVisibility1('none');
      setVisibility3('none');
    }
    setToggle2(!toggle2);
  };

  const handleVisionVisibility = (e) => {
    e.preventDefault();
    if (visibility3 === 'block') {
      setVisibility3('none');
    } else {
      setVisibility3('block');
      setVisibility1('none');
      setVisibility2('none');
    }
    setToggle3(!toggle3);
  };

  return (
    <Wrapper id="about">
      <Container>
        <Row>
          <RightColumn>
            <RightImg>
              <img src="images/blueprint.jpg" alt="blueprint" />
              <video controls>
                <source
                  src="video/10810479-sd_640_360_30fps.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </RightImg>
          </RightColumn>
          <LeftPanel>
            <SideLabel>
              <h3>About our company</h3>
            </SideLabel>
            <p>
              We take pride in quality infrastructure Development with about 10
              years of unparrallel success providing value to our stakeholders
              and clients.
            </p>
            <PanelGroup>
              <PanelWrap>
                <PanelHeading visibility={visibility1}>
                  <h4>
                    {
                      <a href="#collapseOne" onClick={handleHistoryVisibility}>
                        Our History {toggle1 ? <DropDown /> : <RightArrow />}
                      </a>
                    }
                  </h4>
                </PanelHeading>
                <ContentBox id="collapseOne" visibility={visibility1}>
                  <PanelBody>
                    Jesohchristy Construction Nigeria Limited was incorporated
                    in JULY 2015. We stand as one of the fastest growing and
                    innovative Building & Civil Engineering Contractors in
                    Nigeria.
                  </PanelBody>
                </ContentBox>
              </PanelWrap>
              <PanelWrap>
                <PanelHeading visibility={visibility2}>
                  <h4>
                    <a href="#collapseTwo" onClick={handleMissionVisibility}>
                      Our Mission {toggle2 ? <DropDown /> : <RightArrow />}
                    </a>
                  </h4>
                </PanelHeading>
                <ContentBox id="CollapseTwo" visibility={visibility2}>
                  <PanelBody>
                    To provide composite engineering service of high quality
                    delivery, through hardwork and Integrity.
                  </PanelBody>
                </ContentBox>
              </PanelWrap>
              <PanelWrap>
                <PanelHeading visibility={visibility3}>
                  <h4>
                    <a href="#collapseThree" onClick={handleVisionVisibility}>
                      Our Vision
                      {toggle3 ? <DropDown /> : <RightArrow />}
                    </a>
                  </h4>
                </PanelHeading>
                <ContentBox id="collapseThree" visibility={visibility3}>
                  <PanelBody>
                    We aim to become a Globally renowed Construction Company for
                    reliability in Quality and innovative infrastructure
                    delivery.
                  </PanelBody>
                </ContentBox>
              </PanelWrap>
            </PanelGroup>
          </LeftPanel>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default About;

const Wrapper = styled.div`
  padding: 100px 0;

  @media only screen and (max-width: 991px) {
    padding: 80px 0;
  }
`;

const Container = styled.div`
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (max-width: 480px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 750px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-right: -15px;
  margin-left: -15px;

  @media only screen and (min-width: 360px) and (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;

const RightColumn = styled.div`
  width: 50%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 768px) {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  @media only screen and (min-width: 360px) and (max-width: 768px) {
    width: 100vw;
    padding-right: 25px;
    padding-left: 25px;
  }
`;

const RightImg = styled.div`
  background: #000;
  position: relative;
  margin-top: 20px;

  img {
    opacity: 0.6;
    transition: opacity 0.35s;
    max-width: 100%;
    height: 430px;
    vertical-align: middle;
    border: 0;
  }

  video {
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 11;
  }
`;

const LeftPanel = styled.div`
  width: 50%;
  position: relative;
  min-height: 1px;
  padding-right: 20px;
  padding-left: 20px;

  p {
    margin-top: 5px;
    margin-bottom: 26px;
  }

  @media (min-width: 768px) {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media only screen and (min-width: 360px) and (max-width: 768px) {
    width: 100vw;
    padding-right: 25px;
    padding-left: 25px;
  }
`;

const SideLabel = styled.div`
  margin-bottom: 20px;

  h3 {
    color: #101010;
    font-size: 30px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 0;
    position: relative;
    display: block;
    padding-bottom: 25px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 26px;
    text-align: left;

    &:after {
      content: '';
      position: absolute;
      width: 100px;
      background-color: #f57c00;
      height: 5px;
      left: 0;
      bottom: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    h3 {
      font-size: 24px;
    }
  }
`;

const PanelGroup = styled.div`
  margin-bottom: 0;
`;

const PanelWrap = styled.div`
  background-color: #fff;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #333;
  border-color: #ddd;
  margin-top: 18px !important;
`;

const PanelHeading = styled.div`
  border-radius: 0;
  position: relative;
  background: #f0f0f0;
  padding: 0;
  transition: 0.4s;
  color: #333;
  border-color: #ddd;
  border-bottom: 0;

  &:hover {
    background: #101010;
    color: #ffffff;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
    line-height: 1.1;
  }

  a {
    /* background: #252525; */
    color: #101010;
    font-size: 20px;
    font-weight: 700;
    padding: 14px 20px;
    display: block;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    outline: none !important;

    background: ${(props) => props.visibility === 'block' && '#252525'};
    color: ${(props) => (props.visibility === 'none' ? '#252525' : '#fff')};

    &:hover {
      color: white;
    }
  }
`;

const DropDown = styled(ArrowDropDownIcon)`
  font-family: 'Material Icons';
  position: absolute;
  right: 0;
  background: #f57c00;
  top: 0;
  width: 2em !important;
  height: 50px !important;
  display: block;
  color: #252525;

  &:hover {
    color: white;
  }
`;

const RightArrow = styled(ArrowRightIcon)`
  font-family: 'Material Icons';
  position: absolute;
  right: 0;
  background: #f57c00;
  top: 0;
  width: 2em !important;
  height: 50px !important;
  display: block;
  color: #252525;

  &:hover {
    color: white;
  }
`;

const ContentBox = styled.div`
  display: ${(props) => props.visibility};
  height: ${(props) => props.visibility === 'none' && 0};
`;

const PanelBody = styled.div`
  border-top-color: #ddd;
  border-top: 1px solid #ddd;
  padding: 10px 0 0;
`;
