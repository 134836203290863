import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Bounce, Slide } from 'react-awesome-reveal';

function ImgSlider() {
  let settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Carousel {...settings} className="control">
      <SlideItem>
        <img src="images/slider3.jpg" alt="crane" />
        <Container>
          <Column>
            <ContentWrap>
              <Bounce>
                <h1>
                  WE <span>DESIGN & BUILD </span>
                  <br />
                  YOUR <span>DREAM</span> PROJECTS.
                </h1>
              </Bounce>
              <Slide>
                <p>
                  From concept to creation, we are with you every steps of the
                  way With passion for excellence and dedication towards
                  building your dream infrastructure into Reality.
                </p>
              </Slide>
              <a href="#about">
                Read More <ArrowRightIcon />
              </a>
            </ContentWrap>
          </Column>
        </Container>
      </SlideItem>
      <SlideItem>
        <img src="images/image11.jpg" alt="metal cutting" />
        <Container>
          <Column>
            <ContentWrap>
              <h1>
                WE <span>DESIGN & BUILD </span>
                <br />
                YOUR <span>DREAM</span> PROJECTS.
              </h1>
              <p>
                From concept to creation, we are with you every steps of the way
                With passion for excellence and dedication towards building your
                dream infrastructure into Reality.
              </p>
              <a href="#about">
                Read More <ArrowRightIcon />
              </a>
            </ContentWrap>
          </Column>
        </Container>
      </SlideItem>
      <SlideItem>
        <img src="images/image22.jpg" alt="cement foundation" />
        <Container>
          <Column>
            <ContentWrap>
              <h1>
                WE DESIGN<span> & BUILD</span>
                <br />
                YOUR <span>DREAM</span> PROJECTS.
              </h1>
              <p>
                From concept to creation, we are with you every steps of the way
                With passion for excellence and dedication towards building your
                dream infrastructure into Reality.
              </p>
              <a href="#about">
                Read More <ArrowRightIcon />
              </a>
            </ContentWrap>
          </Column>
        </Container>
      </SlideItem>
    </Carousel>
  );
}

export default ImgSlider;

const Carousel = styled(Slider)`
  .slick-prev:before {
    position: absolute;
    transition: all 0.8s ease 0s;
    width: 60px;
    left: -50px;
    height: 60px;
    font-size: 30px;
    color: #f57c00;
    border: 1px solid #f57c00;
    line-height: 58px;
  }

  .slick-next:before {
    position: absolute;
    transition: all 0.8s ease 0s;
    left: 50px;
    width: 60px;
    font-size: 30px;
    color: #f57c00;
    border: 1px solid #f57c00;
    line-height: 58px;
  }

  &.control:hover {
    .slick-prev:before {
      left: 60px;
    }
  }

  &.control:hover {
    .slick-next:before {
      left: -100px;
    }
  }

  button {
    z-index: 1;
  }

  @media only screen and (max-width: 767px) {
    button {
      display: none !important;
    }
  }
`;

const SlideItem = styled.div`
  img {
    width: 100%;
    height: 605px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media only screen and (max-width: 991px) {
    img {
      height: 520px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    img {
      height: 400px !important;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 22%;
  left: 0;
  right: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (max-width: 480px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media only screen and (max-width: 991px) {
    margin-top: 20px;
    top: 0;
  }

  @media only screen and (max-width: 767px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;

const Column = styled.div`
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 30px;

  @media only screen and (max-width: 480px) {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ContentWrap = styled.div`
  z-index: 1000;
  position: relative;
  max-width: 725px;
  /* padding-left: 20px; */

  h1 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.1;
    text-align: start;

    span {
      color: #f57c00;
    }
  }

  p {
    padding: 0;
    color: #ffffff;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 50px;
    letter-spacing: 1px;
    text-align: start;
  }

  a {
    display: flex;
    background: #f57c00;
    padding: 14px 52px;
    font-size: 15px;
    text-transform: uppercase;
    color: #101010;
    float: left;
    font-weight: 400;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    outline: none !important;
  }

  @media only screen and (max-width: 1199px) {
    h1 {
      font-size: 45px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 590px) {
    h1 {
      font-size: 25px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 20px;
    }

    a {
      padding: 8px 25px;
    }
  }

  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    p {
      display: none;
    }
  }
`;

const ArrowRightIcon = styled(KeyboardDoubleArrowRightIcon)`
  font-weight: 700;
  margin-left: 12px;
  position: relative;
  left: 0;
`;
