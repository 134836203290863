import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import { EmailSharp, Phone } from '@mui/icons-material';

function Banner() {
  return (
    <BannerWrapper>
      <Container>
        <Row>
          <Column>
            <LeftItem>
              <ul>
                <li>
                  <PhoneIcon />
                  <a href="tel:(+234)8030551461">Call Us (+234)803-055-1461</a>
                </li>
                <li>
                  <EmailIcon />
                  <a href="mailto:info@jccnigltd.com">info@jccnigltd.com</a>
                </li>
              </ul>
            </LeftItem>
          </Column>
          <Column>
            <RightItem>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/DoneDeck?mibextid=ZbWKwL"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedIn />
                  </a>
                </li>
                <li>
                  <a
                    href="https://rssfeed.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RssFeed />
                  </a>
                </li>
                <li>
                  <a
                    href="https://pinterest.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Pinterest />
                  </a>
                </li>
              </ul>
            </RightItem>
          </Column>
        </Row>
      </Container>
    </BannerWrapper>
  );
}

export default Banner;

const BannerWrapper = styled.div`
  background-color: rgba(37, 37, 37, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 7px 0;

  @media screen and (max-width: 991px) {
    display: none !important;
  }
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
`;

const Column = styled.div`
  width: 50%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`;

const LeftItem = styled.div`
  display: flex;
  align-items: center;
  color: #fff;

  ul {
    /* margin-right: 10px; */
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    li {
      align-items: center;
      justify-content: center;
      display: flex;
      margin-right: 25px;
      color: #fff;

      a {
        text-decoration: none !important;
        outline: none !important;
        color: #fff;
      }
    }
  }
`;

const PhoneIcon = styled(Phone)`
  color: #f57c00;
  margin-right: 10px;
  font-size: 1.2rem !important;
`;

const EmailIcon = styled(EmailSharp)`
  color: #f57c00;
  margin-right: 10px;
  font-size: 1.2rem !important;
`;

const RightItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0px 5px;
    }
  }
`;

const Facebook = styled(FacebookIcon)`
  color: white;
  font-size: 1.1rem !important;

  &:hover {
    color: #f57c00;
  }
`;

const Twitter = styled(XIcon)`
  color: white;
  font-size: 1.1rem !important;

  &:hover {
    color: #f57c00;
  }
`;

const LinkedIn = styled(LinkedInIcon)`
  color: white;
  font-size: 1.1rem !important;

  &:hover {
    color: #f57c00;
  }
`;

const RssFeed = styled(RssFeedIcon)`
  color: white;
  font-size: 1.1rem !important;

  &:hover {
    color: #f57c00;
  }
`;

const Pinterest = styled(PinterestIcon)`
  color: white;
  font-size: 1.1rem !important;

  &:hover {
    color: #f57c00;
  }
`;
