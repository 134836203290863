import React from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import WorkIcon from '@mui/icons-material/Work';
import styled from 'styled-components';

function Counter() {
  return (
    <Wrapper>
      <Overlay></Overlay>
      <Container>
        <Row>
          <CountColumn>
            <VerifiedIcon />
            <h2>100%</h2>
            <h3>Service Quality</h3>
          </CountColumn>
          <CountColumn>
            <GroupsIcon />
            <h2>200+</h2>
            <h3>Satisfied Client</h3>
          </CountColumn>
          <CountColumn>
            <AssignmentTurnedInIcon />
            <h2>150+</h2>
            <h3>Completed Projects</h3>
          </CountColumn>
          <CountColumn>
            <WorkIcon />
            <h2>10</h2>
            <h3>Years of Experience</h3>
          </CountColumn>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Counter;

const Wrapper = styled.div`
  background: url(images/image3.jpg) no-repeat scroll 0 0;
  background-attachment: fixed;
  transition: all 0.3s ease 0s;
  background-size: cover;
  background-position: center center;
  padding: 100px 0;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(37, 37, 37, 0.8);
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (min-width: 320px) and (max-width: 660px) {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
  }
`;

const CountColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    font-size: 3rem !important;
    display: flex;
    color: #f57c00;
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    padding: 0;
    color: #ffffff;
    margin-top: 26px !important;
    margin-bottom: 7px !important;
  }

  h3 {
    color: #cacaca;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-left: 5px;
    font-size: 18px;
  }

  @media (min-width: 320px) and (max-width: 660px) {
    margin-bottom: 15px;
  }
`;
