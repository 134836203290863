import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Projects() {
  // const [slidesToShow, setSlidesToShow] = useState(3);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth <= 580) {
  //       setSlidesToShow(1); // On small screens, show only 1 slide
  //     } else if (window.innerWidth > 581 && window.innerWidth < 1000) {
  //       setSlidesToShow(2); // On larger screens, show 3 slides
  //     } else {
  //       setSlidesToShow(3);
  //     }
  //   };

  //   // Add event listener
  //   window.addEventListener('resize', handleResize);

  //   // Call handler right away so state gets updated with initial window size
  //   handleResize();

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  let settings = {
    dots: true,
    arrows: false,
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const categories = [
    {
      id: 1,
      group: ['all', 'category-b'],
      title: (
        <span>
          Aesthetic Designs. <br /> Your House Our Idea
        </span>
      ),
      description: (
        <span>
          Location: Ikeja, Lagos, Nigeria, <br />
          Client: Private, <br />
          Contract Duration: 6 Months, <br />
          info: Building Renovations, external and interior finishes. Decorative
          Floor & ceiling designs.
        </span>
      ),
      imageUrl: 'images/house_renovations.jpeg',
    },
    {
      id: 2,
      group: ['all', 'category-a', 'category-c'],
      title: (
        <span>
          National Theatre <br /> Police station
        </span>
      ),
      description: (
        <span>
          Location: Lagos, Nigeria, <br />
          Client: NPF, <br />
          Contract Duration: 12 Months,
        </span>
      ),
      imageUrl: 'images/theathre.jpeg',
    },
    {
      id: 3,
      group: ['all', 'category-c', 'category-a'],
      title: 'Ogba Multipurpose Modern International Market',
      description: (
        <span>
          Location: Ogba, Lagos. <br />
          Client: LGA, <br />
          Contract Duration: 12 Months, <br />
          Info: demolition of existing structures and construction of
          structures.
        </span>
      ),
      imageUrl: 'images/ogba.jpeg',
    },
    {
      id: 4,
      group: ['all', 'category-b', 'category-d'],
      title: 'House & Exterior',
      description: (
        <span>
          Location: Ogun State, <br />
          Client: Private, <br />
          Contract Duration: 3 Months, <br />
          Info: Floor preparation and Installation of Interlocking stones
        </span>
      ),
      imageUrl: 'images/exterior.jpeg',
    },
    {
      id: 5,
      group: ['all', 'category-d'],
      title: 'Form-works Fabrication',
      description: (
        <span>
          Location: Lagos, Nigeria, <br />
          Client: Company, <br />
          Contract Duration: 8 Months, <br />
          Info: Pre- Cast Structural elements such as Beams, Diaphragm
          slabs,Parapets etc. Fabrication and Installation of steel Trusses.
        </span>
      ),
      imageUrl: 'images/form-works.jpeg',
    },
    {
      id: 6,
      group: ['all', 'category-d'],
      title: 'All types of Foundations',
      description: (
        <span>
          Location: Lagos, Nigeria, <br />
          Client: Company, <br />
          Contract Duration: 3 Months, <br />
          Info: Construction of Piles foundation, Raft, Roads and Drainage e.t.c
          .
        </span>
      ),
      imageUrl: 'images/pile_foundation.jpeg',
    },
  ];

  const [dataFilters, setdataFilters] = useState('all');

  const handlefilter = (category) => {
    setdataFilters(category);
  };
  const filteredCategories = categories.filter((category) =>
    category.group.includes(dataFilters)
  );

  return (
    <Wrapper id="projects">
      <Container>
        <HeaderTitle>
          <h3>Our Projects</h3>
        </HeaderTitle>
        <ProjectWrap>
          <BtnFilters>
            <button data-filter="*" onClick={() => handlefilter('all')}>
              ALL
            </button>
            <button
              data-filter="category-a"
              onClick={() => handlefilter('category-a')}
            >
              Building
            </button>
            <button
              data-filter="category-b"
              onClick={() => handlefilter('category-b')}
            >
              House & Exterior
            </button>
            <button
              data-filter="category-c"
              onClick={() => handlefilter('category-c')}
            >
              Construction
            </button>
            <button
              data-filter="category-d"
              onClick={() => handlefilter('category-d')}
            >
              Design
            </button>
          </BtnFilters>
          <Carousel {...settings}>
            {filteredCategories.map(({ imageUrl, id, title, description }) => (
              <SliderItems key={id}>
                <Slide>
                  <ProjectItem className="popup popTitle">
                    <ProjectImg>
                      <img src={imageUrl} alt="" />
                    </ProjectImg>
                    <ProjectTitle className="popTitle">
                      <h3>
                        <a href="#project-details">{title}</a>
                      </h3>
                      <p>See Project...</p>
                    </ProjectTitle>
                    <ProjectContent className="popup border">
                      <OverlayBorder className="border"></OverlayBorder>
                      <DisplayTable>
                        <TableCell>
                          <h3>
                            <a href="#project-details">{title}</a>
                          </h3>
                          <p>{description}</p>
                          <a href="#project-details">See Project...</a>
                        </TableCell>
                      </DisplayTable>
                    </ProjectContent>
                  </ProjectItem>
                </Slide>
              </SliderItems>
            ))}
          </Carousel>
        </ProjectWrap>
      </Container>
    </Wrapper>
  );
}

export default Projects;

const Wrapper = styled.div`
  padding: 100px 0;
  overflow: hidden !important;
`;

const HeaderTitle = styled.div`
  margin-bottom: 25px;
  text-align: center;

  h3 {
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 0;
    position: relative;
    display: block;
    padding-bottom: 25px;
    text-transform: uppercase;

    &:after {
      content: '';
      position: absolute;
      left: 45%;
      bottom: 0;
      height: 5px;
      width: 100px;
      background: #f57c00;
    }
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  /* overflow-x: hidden; */
`;

const ProjectWrap = styled.div`
  padding-bottom: 8px;
`;

const BtnFilters = styled.div`
  padding: 0 30px;
  margin-bottom: 30px;
  text-align: center;

  button {
    background: transparent;
    position: relative;
    display: inline-block;
    border: 0;
    outline: none;
    font-weight: 400;
    font-size: 18px;
    color: #505050;
    transition: all 0.3s ease 0s;
    margin: 0 30px 15px 0;
    cursor: pointer;
  }
`;

const Carousel = styled(Slider)`
  .slick-slide {
    /* width: auto !important; */
    /* margin-right: 20px; */
  }
`;

const SliderItems = styled.div``;

const Slide = styled.div`
  /* width: 407.667px; */
  margin-right: 20px;

  @media (min-width: 580px) and (max-width: 992px) {
    margin: 0 20px;
    width: auto;
  }

  @media (min-width: 320px) and (max-width: 580px) {
    margin: 0 50px;
    width: auto;
  }
`;

const ProjectItem = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  z-index: 11;

  &:hover .popup {
    position: absolute;
    top: 0;
    text-align: center;
    opacity: 1;

    /* border: 5px solid #e64a19; */
  }

  &:hover .popTitle {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all ease-out;
  }
`;

const ProjectImg = styled.div`
  img {
    max-width: 100%;
    height: 540px;
  }

  /* @media (min-width: 580px) and (max-width: 992px) {
    width: 500px;
  }

  @media (min-width: 320px) and (max-width: 580px) {
    width: 400px;
  } */
`;

const ProjectTitle = styled.div`
  padding: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  background: rgba(0, 0, 0, 0.8);

  /* &:hover {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all ease-out;
  }  */

  h3 {
    margin: 0 0 20px;
    color: #fff;
    font-size: 24px;
  }

  a {
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    outline: none !important;
    color: #fff;
    background-color: transparent;
  }

  p {
    font-size: 15px;
    color: #ffffff;
    font-weight: 400;
  }
`;

const ProjectContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 110%;
  left: 0;
  text-align: left;
  z-index: 11;
  padding: 15px 35px;
  transition: 0.5s all ease-out;

  &:hover .popTitle {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all ease-out;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: -1;
    -webkit-transition: 0.25s opacity ease;
    transition: 0.25s opacity ease;
  }

  &:hover .border::before,
  .border::after {
    opacity: 1;
    transform: scale(1);
  }
`;

const DisplayTable = styled.div`
  display: table;
  height: 100%;
  width: 100%;
`;

const TableCell = styled.div`
  display: table-cell;
  vertical-align: middle;

  h3 {
    margin-bottom: 25px;
    line-height: normal;
    color: #fff;

    a {
      font-size: 24px;
      line-height: 35px;
      color: #fff;
      font-weight: 700;
      transition: all 0.3s ease 0s;
      text-decoration: none !important;
      outline: none !important;
    }
  }

  p {
    margin-bottom: 25px;
    font-size: 15px;
    color: #ccc;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  a {
    color: #e64a19;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    outline: none !important;
  }
`;

const OverlayBorder = styled.div`
  &:before,
  &:after {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    z-index: 1;
    border: 5px solid #e64a19;
    /* border-bottom: 5px solid #e64a19; */
    /* transform: scale(0, 1); */
  }
`;
