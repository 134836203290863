import React from 'react';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CabinIcon from '@mui/icons-material/Cabin';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EngineeringIcon from '@mui/icons-material/Engineering';
import RoofingIcon from '@mui/icons-material/Roofing';
import ConstructionIcon from '@mui/icons-material/Construction';
import styled from 'styled-components';

function Services() {
  return (
    <ServiceWrapper id="services">
      <Container>
        <HeaderTitle>
          <h3>Our Services</h3>
        </HeaderTitle>
        <Row>
          <ServiceColumn>
            <ServicesLists>
              <Card>
                <CustomIcon>
                  <LocationCityIcon />
                </CustomIcon>
                <ServiceDetails>
                  <h3>Building Construction</h3>
                  <p>
                    We specialize in constructing high quality residential and
                    commercial spaces that meet your home and business needs.
                    Whether it’s single-family homes to multi-unit developments,
                    an office complex, retail store, or industrial facility, our
                    expertise ensures functional and aesthetically pleasing
                    structures.
                  </p>
                </ServiceDetails>
              </Card>
              <Card>
                <CustomIcon>
                  <CabinIcon />
                </CustomIcon>
                <ServiceDetails>
                  <h3>Renovations & Remodelling</h3>
                  <p>
                    Looking to enhance or transform an existing space? Our
                    renovation services cover everything from minor updates to
                    complete overhauls. We enhance functionality, energy
                    efficiency, and overall appeal.
                  </p>
                </ServiceDetails>
              </Card>
              <Card>
                <CustomIcon>
                  <AccountTreeIcon />
                </CustomIcon>
                <ServiceDetails>
                  <h3>Project Management</h3>
                  <p>
                    Leave the complexities to us. Our experienced project
                    managers oversee every aspect, ensuring timely completion,
                    budget adherence, and quality control.
                  </p>
                </ServiceDetails>
              </Card>
              <Card>
                <CustomIcon>
                  <EngineeringIcon />
                </CustomIcon>
                <ServiceDetails>
                  <h3>Construction Consulting</h3>
                  <p>
                    We provide expert advice, guidance, and support to clients
                    in the construction industry that involves assistance with
                    various construction-related activities from cost estimation
                    to design and planning
                  </p>
                </ServiceDetails>
              </Card>
              <Card>
                <CustomIcon>
                  <RoofingIcon />
                </CustomIcon>
                <ServiceDetails>
                  <h3>Custom Installations</h3>
                  <p>
                    Our custom home construction services bring your vision to
                    life. We collaborate closely with clients to create unique,
                    functional, and beautiful living spaces. From custom home
                    construction to electrical and mechanical installations, we
                    prioritize safety, quality, and client satisfaction.
                  </p>
                </ServiceDetails>
              </Card>
              <Card>
                <CustomIcon>
                  <ConstructionIcon />
                </CustomIcon>
                <ServiceDetails>
                  <h3>General Contracting</h3>
                  <p>
                    We provide contracting service responsible for providing all
                    of the material and services necessary for the construction
                    of the project. We prioritize sustainability and incorporate
                    eco-friendly materials, energy-efficient designs, and green
                    building practices to minimize environmental impact.
                  </p>
                </ServiceDetails>
              </Card>
            </ServicesLists>
          </ServiceColumn>
        </Row>
      </Container>
    </ServiceWrapper>
  );
}

export default Services;

const ServiceWrapper = styled.div`
  background-color: #252525;
  padding: 100px 0;
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1200px) {
    width: 1170px;
  }

  /* @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 768px) {
    width: 750px;
  } */
`;

const HeaderTitle = styled.div`
  margin-bottom: 50px;
  text-align: center;

  h3 {
    color: #ffffff;
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 0;
    position: relative;
    display: block;
    padding-bottom: 25px;
    text-transform: uppercase;

    &:after {
      content: '';
      position: absolute;
      transform: translateX(-50%);
      bottom: 0;
      left: 50%;
      height: 5px;
      width: 100px;
      background: #f57c00;
    }
  }

  @media only screen and (max-width: 480px) {
    h3 {
      font-size: 24px;
    }
  }
`;

const Row = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`;

const ServiceColumn = styled.div`
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`;

const ServicesLists = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;

  @media (min-width: 320px) and (max-width: 992px) {
    width: 100%;
    display: block;
  }
`;

const Card = styled.a`
  padding: 50px 25px;
  display: flex;
  overflow: hidden;
  color: #f57c00;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  outline: none !important;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 15px;
    padding: 20px;
  }

  &:hover {
    background: #f57c00;
  }
`;

const CustomIcon = styled.div`
  margin-right: 25px;
  font-size: 3rem !important;
  line-height: 50px;
  color: #e64a19;

  svg {
    font-size: 3rem !important;
  }
`;

const ServiceDetails = styled.div`
  overflow: hidden;

  h3 {
    color: #ffffff;
    font-size: 22px;
    margin: 0 0 20px;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: #ddd !important;
  }
`;
