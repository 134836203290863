import React from 'react';
import styled from 'styled-components';
import HighQualityIcon from '@mui/icons-material/HighQuality';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AnalyticsIcon from '@mui/icons-material/Analytics';

function OurValues() {
  return (
    <Wrapper>
      <HeaderTitle>
        <h3>Why Choose Us?</h3>
        <p>
          Our reputation is based on professional standards and core values we
          operate.
        </p>
      </HeaderTitle>
      <Container>
        <ValueItem>
          <CustomIcon>
            <HighQualityIcon />
          </CustomIcon>
          <h4>High quality</h4>
        </ValueItem>
        <ValueItem>
          <CustomIcon>
            <LightbulbCircleIcon />
          </CustomIcon>
          <h4>Innovation</h4>
        </ValueItem>
        <ValueItem>
          <CustomIcon>
            <ThumbUpIcon />
          </CustomIcon>
          <h4>Integrity</h4>
        </ValueItem>
        <ValueItem>
          <CustomIcon>
            <AnalyticsIcon />
          </CustomIcon>
          <h4>Hard Work</h4>
        </ValueItem>
      </Container>
    </Wrapper>
  );
}

export default OurValues;

const Wrapper = styled.div`
  width: 100%;
  padding: 50px 0;
  background: oldlace;
`;

const HeaderTitle = styled.div`
  margin-bottom: 50px;
  text-align: center;

  h3 {
    color: black;
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 0;
    position: relative;
    display: block;
    padding-bottom: 25px;
    text-transform: uppercase;

    &:after {
      content: '';
      position: absolute;
      transform: translateX(-50%);
      bottom: 0;
      left: 50%;
      height: 5px;
      width: 100px;
      background: #f57c00;
    }
  }

  p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;

  @media (min-width: 320px) and (max-width: 660px) {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
  }
`;

const ValueItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    text-transform: uppercase;
    font-size: small;
  }

  @media (min-width: 320px) and (max-width: 660px) {
    margin-bottom: 15px;
  }
`;

const CustomIcon = styled.div`
  color: #f57c00;
  border: 1px solid #e64a19;
  border-radius: 100%;
  padding: 20px;
  margin-bottom: 20px;

  svg {
    font-size: 3rem !important;
    display: flex;
  }
`;
