import React from 'react';
import styled from 'styled-components';
import ImgSlider from './ImgSlider';
import CallToAction from './CallToAction';
import About from './About';
import Services from './Services';
import OurValues from './OurValues';
import Counter from './Counter';
import Projects from './Projects';
import Testimonial from './Testimonial';
import OurTeam from './OurTeam';
import Partners from './Partners';
import Footer from '../footer/Footer';

function Home() {
  return (
    <Container>
      <ImgSlider />
      <CallToAction />
      <About />
      <Services />
      <OurValues />
      <Counter />
      <Projects />
      <Testimonial />
      <OurTeam />
      <Partners />
      <Footer />
    </Container>
  );
}

export default Home;

const Container = styled.main``;
