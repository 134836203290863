import { EmailOutlined, Person } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function Form() {
  const [status, setStatus] = useState('submit');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');

    const { name, email, message } = e.target;
    let formDetails = {
      name: name.value,
      email: email.value,
      message: message.value,
    };

    const response = await fetch('mailer/index.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(formDetails),
    });

    const result = await response.json();

    setStatus('Submit');

    if (result.message === 'success') {
      alert('Message sent');

      document.getElementsByClassName('form-control').reset();

      navigate('/contact');
    } else {
      alert('Message failed to send');
    }
  };

  return (
    <Sectionwrap>
      <Wrap>
        <Row>
          <Column>
            <h4>Location</h4>
            <MapOutline>
              <MapCanvas>
                <iframe
                  title="Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15852.293624730013!2d3.365645619067438!3d6.637806273168257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9376cafe3345%3A0xa1a5fba94475adf!2s23%20Abiodun%20Bero%20Ave%2C%20Olowora%2C%20Street%20105102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1720993026051!5m2!1sen!2sng"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </MapCanvas>
            </MapOutline>
          </Column>
          <Column>
            <h4>Contact Form</h4>
            <FormWrap>
              {/* <form method="post" action="mailer/index.php"> */}
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <InputGroup>
                        <span>
                          <Person />
                        </span>
                        <input
                          className="form-control"
                          name="name"
                          type="text"
                          required
                          placeholder="Name"
                        />
                      </InputGroup>
                    </FormGroup>
                  </FormColumn>
                  <FormColumn>
                    <FormGroup>
                      <InputGroup>
                        <span>
                          <EmailOutlined />
                        </span>
                        <input
                          className="form-control"
                          name="email"
                          type="email"
                          required
                          placeholder="Email"
                        />
                      </InputGroup>
                    </FormGroup>
                  </FormColumn>
                  <FormColumn>
                    <FormGroup>
                      <InputGroup>
                        <span>
                          <EditIcon />
                        </span>
                        <textarea
                          className="form-control"
                          name="message"
                          type="text"
                          rows={1}
                          required
                          placeholder="Message"
                        />
                      </InputGroup>
                    </FormGroup>
                  </FormColumn>
                  <ButtonWrap>
                    <button type="submit">{status}</button>
                  </ButtonWrap>
                </FormRow>
              </form>
            </FormWrap>
          </Column>
        </Row>
      </Wrap>
    </Sectionwrap>
  );
}

export default Form;

const Sectionwrap = styled.div`
  margin-bottom: 50px;
`;

const Row = styled.div`
  display: flex;
  margin-top: 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Wrap = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 1200px) {
    width: 100%;
  }
`;

const Column = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 769px) {
    width: 50%;
  }

  h4 {
    text-transform: uppercase !important;
    margin-bottom: 55px;
    margin-left: 0;

    &::before {
      content: '';
      position: absolute;
      top: 30px;
      left: 15px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 3px solid #2d3239;
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 25px;
      left: 35px;
      height: 3px;
      margin: 0 0 0;
      width: 70px;
      margin: 10px 0;
      background-color: #f57c00;
    }
  }
`;

const MapOutline = styled.div`
  margin-bottom: 30px;
`;

const MapCanvas = styled.div`
  width: 100%;

  iframe {
    width: 100%;
    height: 300px;
    border: 0;
  }
`;

const FormWrap = styled.div`
  background-color: #f5f6f6;
  padding: 30px;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 0.75rem;
`;

const FormColumn = styled.div`
  max-width: 100%;
  margin-top: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  span {
    background: #fff;
    border-color: #e1e1e1;
    padding: 6px 14px;
    font-size: 16px;
    color: #545454;
    border-width: 1px;
    border-style: solid;

    svg {
      color: #545454;
    }
  }

  input {
    border-color: #e1e6eb;
    border: 1px solid #ced4da;
    box-shadow: none;
    /* height: 40px; */
    font-size: 13px;
    line-height: 20px;
    padding: 9px 12px;

    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }

  textarea {
    height: 82px;
    position: relative;
    flex: 1 1 auto;
    /* width: 1%; */
    min-width: 0;
    border-color: #e1e6eb;
    box-shadow: none;
    font-size: 13px;
    line-height: 20px;
    padding: 9px 12px;
    border: 1px solid #ced4da;
  }
`;

const ButtonWrap = styled.div`
  button {
    background: #f57c00;
    font-weight: 700;
    color: #101010;
    font-size: 15px;
    line-height: 24px;
    border: none;
    border-radius: 0px;
    padding: 14px 23px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 14px;
    transition: all 0.3s ease 0s;
  }
`;
