import { EmailOutlined, LocationOn, PhoneIphone } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import Form from './Form';
import Footer from '../footer/Footer';

function Contact() {
  return (
    <>
      <Wrapper>
        <Image className="breadcrumb"> </Image>
        <InnerWrap>
          <Container>
            <HeaderRow>
              <h1>Contact Us</h1>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>contact us</li>
              </ul>
            </HeaderRow>
          </Container>
        </InnerWrap>
      </Wrapper>
      <ContactSection>
        <Container>
          <Row>
            <Column>
              <Card>
                <CustomIcon>
                  <PhoneIphone />
                </CustomIcon>
                <InfoText>
                  <h4>
                    Phone: <a href="tel:(+088)589-8745">(+234)8030551461</a>
                  </h4>
                  <p>
                    Mobile: <a href="tel:(+088)589-8745">(+234)8179721431</a>
                  </p>
                </InfoText>
              </Card>
            </Column>
            <Column className="middle">
              <Card>
                <CustomIcon>
                  <EmailOutlined />
                </CustomIcon>
                <InfoText>
                  <h4>
                    Email:{' '}
                    <a href="mailto:info@jccnigltd.com">info@jccnigltd.com</a>
                  </h4>
                  <p>
                    Other:{' '}
                    <a href="mailto:jccnigltd@gmail.com">jccnigltd@gmail.com</a>
                  </p>
                </InfoText>
              </Card>
            </Column>
            <Column>
              <Card>
                <CustomIcon>
                  <LocationOn />
                </CustomIcon>
                <InfoText>
                  <p>23, Abiodun Bero, Abiodun Bero Estate Magodo Phase 1.</p>
                </InfoText>
              </Card>
            </Column>
          </Row>
        </Container>
      </ContactSection>
      <Form />
      <Footer />
    </>
  );
}

export default Contact;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #f9f9f9;

  /* img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  } */
`;

const Image = styled.div`
  background-image: Url('images/slider2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: top;
  height: 300px;
  width: 100%;
  max-width: 100%;
`;

const InnerWrap = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 11;
  top: 65%;
  transform: translateY(-50%);
  max-width: 100%;
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

const HeaderRow = styled.div`
  margin-right: -15px;
  margin-left: -15px;

  h1 {
    font-size: 36px;
    margin-bottom: 0;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-align: center;
  }

  ul {
    display: inline-block;
    padding: 4px 20px;
    list-style: outside none none;
    margin: 0;

    li {
      color: #f57c00;
      display: inline-block;
      font-size: 15px;

      a {
        text-decoration: none !important;
        color: #ffffff;
        padding-right: 30px;

        &:before {
          background-color: #cccccc;
          content: '';
          height: 15px;
          width: 2px;
          position: absolute;
          left: 618px;
          bottom: 10px;
          transform: rotate(33deg);
        }

        &:after {
          background-color: #cccccc;
          content: '';
          height: 15px;
          width: 2px;
          position: absolute;
          right: 636px;
          bottom: 10px;
          transform: rotate(33deg);
        }
      }
    }
  }
`;

const ContactSection = styled.div`
  padding: 100px 0;

  @media only screen and (max-width: 991px) {
    padding: 80px 0;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 40px;
  margin-right: -15px;
  margin-left: -15px;

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Column = styled.div`
  width: 100%;

  @media only screen and (max-width: 767px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;

const Card = styled.div`
  background: #ddd;
  padding: 40px 15px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const CustomIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f57c00;
  color: #101010;
  margin: 0 auto 15px;
  font-size: 20px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
`;

const InfoText = styled.div`
  color: #101010;
  text-align: center;

  a {
    text-decoration: none !important;
    color: #101010;
  }
`;
