import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Banner from './../banner/Banner';
import { Outlet, NavLink, Link, useLocation } from 'react-router-dom';

function Header() {
  const [top, setTop] = useState(40.67);
  const [visible, setVisibilty] = useState(false);

  const { pathname, hash, key } = useLocation();

  const checkScroll = () => {
    window.scrollY > 20 ? setTop(0) : setTop(40.67);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  const handleVisibility = () => {
    setVisibilty(!visible);
  };

  useEffect(() => {
    if (hash === '') window.scrollTo(0, 0);
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth',
          });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <>
      <MainHeader>
        <Banner />
        <Wrapper $top={top}>
          <Container>
            <Row>
              <LeftColumn>
                <Logo>
                  <NavLink to="/">
                    <img src="images/Jccnigltd.png" alt="logo" />
                  </NavLink>
                </Logo>
              </LeftColumn>
              <RightColumn>
                <ToggleButton $visible={visible} onClick={handleVisibility}>
                  <span className="icon-bar-one"></span>
                  <span className="icon-bar-two"></span>
                  <span className="icon-bar-three"></span>
                </ToggleButton>
                <Navigation $visible={visible}>
                  <NavMenu>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/#about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/#services">Services</Link>
                    </li>
                    <li>
                      <Link to="/#projects">Projects</Link>
                    </li>
                    <li>
                      <Link to="contact">Contact</Link>
                    </li>
                  </NavMenu>
                </Navigation>
              </RightColumn>
            </Row>
          </Container>
        </Wrapper>
      </MainHeader>
      <Outlet />
    </>
  );
}

export default Header;

const MainHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 111;
  bottom: auto;
`;

const Wrapper = styled.div`
  position: relative;
  background-color: rgba(37, 37, 37, 0.8);
  position: fixed !important;
  z-index: 9999;
  width: 100%;
  top: ${(props) => props.$top};
  transition: 0.2s;

  @media only screen and (max-width: 991px) {
    position: static !important;
  }
`;

const Container = styled.div`
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  /* @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (min-width: 992px) {
    width: 970px;
  } */

  @media only screen and (max-width: 991px) {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  /* @media (min-width: 768px) {
    width: 750px;
  } */
`;

const Row = styled.div`
  display: flex;
  margin-right: -15px;
  margin-left: -15px;

  @media only screen and (max-width: 991px) {
    display: block;
    justify-content: center;
  }
`;

const LeftColumn = styled.div`
  @media only screen and (max-width: 991px) {
    width: 100%;
    padding: 0;
  }

  @media (min-width: 768px) {
    /* width: 25%; */
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const Logo = styled.div`
  line-height: 90px;
  background-color: rgba(37, 37, 37, 0.2);

  @media only screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
    line-height: 0;
    /* padding: 30px 0; */
    /* background: #000; */
  }

  a {
    text-decoration: none;
    background-color: transparent;
    vertical-align: middle;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    background: gray;

    @media only screen and (min-width: 991px) {
      margin-left: 40px;
    }
  }
`;

const RightColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 991px) {
    background: rgb(34, 34, 34) !important;
    padding: 0 15px;
    justify-content: flex-start;
    width: 100%;
    height: 54px;
  }

  @media (max-width: 768px) {
    /* width: 75%; */
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const ToggleButton = styled.button`
  display: none;

  @media only screen and (max-width: 991px) {
    display: block;
    position: relative;
    background-color: transparent;
    border: 1px solid #222;
    padding: 16px;
    cursor: pointer;
  }

  span {
    background: #fff;
    height: 3px;
    border-radius: 0;
    display: block;
    width: 22px;
    transition: all 0.5s linear;
    margin-top: 4px;
  }

  .icon-bar-two {
    position: ${(props) => (props.$visible ? 'absolute' : 'relative')};
    left: ${(props) => (props.$visible ? '100px' : 0)};
    opacity: ${(props) => (props.$visible ? 0 : 1)};
  }

  .icon-bar-one {
    position: relative;
    transform: ${(props) =>
      props.$visible
        ? 'rotate(45deg) translateX(3px) translateY(2px)'
        : 'rotate(0)'};
  }

  .icon-bar-three {
    transform: ${(props) =>
      props.$visible
        ? 'rotate(-45deg) translateX(3px) translateY(-2px)'
        : 'rotate(0)'};
  }
`;

const Navigation = styled.div`
  position: relative;
  width: 100%;

  @media only screen and (max-width: 991px) {
    display: ${(props) => (props.$visible ? 'block' : 'none')};
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    right: 0;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }
`;

const NavMenu = styled.ul`
  display: flex;
  width: 75%;
  margin-left: auto;
  justify-content: space-evenly;
  text-align: right;
  position: relative;
  list-style: outside none none;

  li {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;

    a {
      text-decoration: none;
      color: white;

      &:active {
        color: #f57c00;
      }

      &:hover {
        color: #f57c00;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    float: none;
    margin: 0px;
    background: rgb(34, 34, 34) !important;
    display: block;
    width: 100%;
    height: 100%;

    li {
      border-bottom: 1px solid #e9e9e9;
      padding: 12px;
    }

    a {
      padding: 12px 12px;
      color: #777;
      transition: all 0.2s linear;
    }
  }
`;
