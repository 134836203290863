import React from 'react';
import styled from 'styled-components';

function CallToAction() {
  return (
    <Wrap>
      <Container>
        <Row>
          <LeftItem>
            <h2>We are ready to build, tell us more about your project</h2>
          </LeftItem>
          <RightItem>
            <a href="contact"> Get in Touch</a>
          </RightItem>
        </Row>
      </Container>
    </Wrap>
  );
}

export default CallToAction;

const Wrap = styled.div`
  background: #f57c00;
  padding: 40px 0;
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (max-width: 780px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;

const Row = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }
`;

const RightItem = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  a {
    font-size: 20px;
    text-transform: uppercase;
    background: #252525;
    color: #ffffff;
    padding: 12px 20px;
    font-weight: 700;
    text-decoration: none !important;
  }

  @media only screen and (max-width: 991px) {
    text-wrap: nowrap;

    a {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 590px) {
    a {
      font-size: 15px;
    }
  }
`;

const LeftItem = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  h2 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0px;
    line-height: 40px;
    color: #0a0a23;
  }

  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 780px) {
    h2 {
      margin-bottom: 25px;
      line-height: normal;
    }
  }

  @media only screen and (max-width: 590px) {
    h2 {
      font-size: 16px;
      text-align: center;
    }
  }
`;
