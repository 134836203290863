import React from 'react';
import GradeIcon from '@mui/icons-material/Grade';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Testimonial() {
  let settings = {
    dots: false,
    arrows: false,
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const datas = [
    {
      id: 1,
      imageUrl: 'images/25175734_7074357.jpg',
      name: 'John Osas',
      testimony:
        'Working with JC construction has been a delight, thier professionalism has always been top notch. I choose them for my next project.',
      position: 'Architect',
    },
    {
      id: 2,
      imageUrl: 'images/33109204_8012782.jpg',
      name: 'Adetola Olutoyin',
      testimony:
        'With JC team handling your project, you are rest assured of quality delivery, within budget and in time.',
      position: 'LandLady',
    },
    {
      id: 3,
      imageUrl: 'images/2151134303.jpg',
      name: 'Odunayo Gbemi',
      testimony:
        'JC project management team has always been my go-to team for development consultations and they deliver every time ',
      position: 'Property Developer',
    },
    {
      id: 4,
      imageUrl: 'images/77148074_JEMA GER 1734-04.png',
      name: 'Mohammed Stobah',
      testimony:
        'With JC team handling your project, you are rest assured of quality delivery, within budget and in time.',
      position: 'Manager',
    },
  ];

  return (
    <Wrapper>
      <Container>
        <HeaderTitle>
          <h3>What Our Client Says</h3>
        </HeaderTitle>
        <Row>
          <Column>
            <Carousel {...settings}>
              {datas.map(({ imageUrl, name, testimony, position, id }) => (
                <SlideItems key={id}>
                  <TestimonialWrap>
                    <ImageWrap>
                      <img
                        src={imageUrl}
                        alt="Freepik https://www.freepik.com/free-vector/hand-drawn-caricature-illustration_33109204.htm#query=face%20illustration&position=11&from_view=keyword&track=ais_user&uuid=7a3438c4-50fd-4a29-ad54-290c3a3b484d"
                      />
                    </ImageWrap>
                    <h3>{name}</h3>
                    <p>{testimony}</p>
                    <Profile>
                      <h6>{position}</h6>
                      <Rating>
                        <GradeIcon />
                        <GradeIcon />
                        <GradeIcon />
                        <GradeIcon />
                        <StarHalfIcon />
                      </Rating>
                    </Profile>
                  </TestimonialWrap>
                </SlideItems>
              ))}
            </Carousel>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Testimonial;

const Wrapper = styled.div`
  background-color: #252525;
  padding: 100px 0;
`;

const HeaderTitle = styled.div`
  margin-bottom: 50px;
  color: #ffffff;
  text-align: center;

  h3 {
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 0;
    position: relative;
    display: block;
    padding-bottom: 25px;
    text-transform: uppercase;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      bottom: 0;
      height: 5px;
      width: 100px;
      background: #f57c00;
    }
  }
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

const Row = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`;

const Column = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`;

const Carousel = styled(Slider)``;

const SlideItems = styled.div``;

const TestimonialWrap = styled.div`
  padding-top: 70px;
  margin: 50px 0 0;
  border: 1px solid #333;
  text-align: center;
  position: relative;
  transition: all 0.7s ease 0s;

  &:hover {
    border-color: #f57c00;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    color: #f57c00;
    text-transform: uppercase;
    margin: 0 0 10px 0;
  }

  p {
    font-size: 15px;
    color: #fff;
    line-height: 25px;
    padding: 0 25px 15px;
    margin: 0;
  }
`;

const ImageWrap = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: -50px;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  transition: all 0.7s ease 0s;
  border: 5px solid #f2f2f2;

  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
`;

const Profile = styled.div`
  h6 {
    font-weight: bold;
    color: #f57c00;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 0;
    transition: all 700ms ease 0s;
  }
`;

const Rating = styled.div`
  display: inline-block;
  margin-bottom: 14px;
  margin-top: 0;
  color: gold;
`;
