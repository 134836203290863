import React from 'react';
import XIcon from '@mui/icons-material/X';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FacebookIcon from '@mui/icons-material/Facebook';

function OurTeam() {
  let settings = {
    dots: false,
    arrows: false,
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const teamData = [
    {
      id: 1,
      imageUrl: 'images/jasam.jpg',
      name: 'Ifere Jesam E.',
      position: 'Project Manager',
      link: 'https://twitter.com/',
    },
    {
      id: 2,
      imageUrl: 'images/csm.jpeg',
      name: 'Adenle Jamiu',
      position: 'Cost Manager',
      link: 'https://twitter.com/',
    },
    {
      id: 3,
      imageUrl: 'images/itam.jpeg',
      name: 'Itam Margaret',
      position: 'Safety Officer/HRM',
      link: 'https://twitter.com/',
    },
    {
      id: 4,
      imageUrl: 'images/profile2.png',
      name: 'Engr Abraham',
      position: 'Projects Engineer',
      link: 'https://twitter.com/',
    },
    {
      id: 5,
      imageUrl: 'images/profile32.png',
      name: 'Engr Donald O.',
      position: 'Construction Manager',
      link: 'https://twitter.com/',
    },
    {
      id: 6,
      imageUrl: 'images/AcctMan.png',
      name: 'Blessing Nwosu',
      position: 'Account Manager',
      link: 'https://twitter.com/',
    },
  ];

  return (
    <Wrapper id="team">
      <Container>
        <HeaderTitle>
          <h3>Our Team Members</h3>
        </HeaderTitle>
        <Carousel {...settings}>
          {teamData.map(({ imageUrl, name, position, link, id }) => (
            <Slide key={id}>
              <TeamItem className="popup">
                <ImageWrap>
                  <img src={imageUrl} alt="" />
                  <Label>
                    <h3>{name}</h3>
                    <p>{position}</p>
                  </Label>
                </ImageWrap>
                <Profile className="popup border">
                  <OverlyBorder className="border"></OverlyBorder>
                  <DisplayTable>
                    <TableCell>
                      <h3>{name}</h3>
                      <span>{position}</span>
                      <p>Hardwork and Integrity</p>
                      <SocialLinks>
                        <a href={link}>
                          <XIcon />
                        </a>
                        <a href="https://facebook.com">
                          <FacebookIcon />
                        </a>
                      </SocialLinks>
                    </TableCell>
                  </DisplayTable>
                </Profile>
              </TeamItem>
            </Slide>
          ))}
        </Carousel>
      </Container>
    </Wrapper>
  );
}

export default OurTeam;

const Wrapper = styled.div`
  padding: 100px 0;
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  /* @media (min-width: 1200px) {
    width: 1170px;
  } */
`;

const HeaderTitle = styled.div`
  margin-bottom: 50px;
  text-align: center;

  h3 {
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 0;
    position: relative;
    display: block;
    padding-bottom: 25px;
    text-transform: uppercase;
    color: #101010;

    &:after {
      content: '';
      position: absolute;
      left: 45%;
      bottom: 0;
      height: 5px;
      width: 100px;
      background: #f57c00;
    }
  }
`;

const Carousel = styled(Slider)``;

const TeamItem = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0;
  margin-left: 15px;
  margin-right: 15px;

  &:hover .popup {
    opacity: 1;
    top: 0;
  }
`;

const Slide = styled.div``;

const ImageWrap = styled.div`
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
`;

const Label = styled.div`
  position: absolute;
  left: -40px;
  bottom: 40px;
  transform: rotate(-90deg);
  text-align: start;

  h3 {
    background: #f57c00;
    color: #101010;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px !important;
    padding: 10px 20px;
    display: block;
  }

  p {
    padding: 10px 20px;
    background: #333;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }
`;

const Profile = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 10%;
  left: 0;
  text-align: center;
  z-index: 11;
  padding: 30px;
  opacity: 0;
  transition: 0.3s all ease-out;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: -1;
    transition: 0.25s opacity ease;
  }

  &:hover .border::before,
  .border::after {
    opacity: 1;
    transform: scale(1);
  }
`;

const OverlyBorder = styled.div`
  &::before,
  &::after {
    border-top: 5px solid #f57c00;
    border-bottom: 5px solid #f57c00;
    transform: scale(0, 1);
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    z-index: 1;
  }
`;

const DisplayTable = styled.div`
  display: table;
  height: 100%;
  width: 100%;
`;

const TableCell = styled.div`
  display: table-cell;
  vertical-align: middle;

  h3 {
    background-color: transparent;
    margin-bottom: 6px;
    font-size: 20px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    z-index: 111;
  }

  span {
    position: relative;
    font-size: 14px;
    color: #f57c00;
    padding-bottom: 12px;

    &::after {
      content: '';
      position: absolute;
      left: 20%;
      bottom: 0;
      width: 50px;
      height: 2px;
      background: #f57c00;
    }
  }

  p {
    color: #fff;
    margin-bottom: 0;
    padding-top: 12px;
    margin-top: 8px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 111;
  margin-top: 25px;
  transition: 0.4s all ease;

  a {
    transition: all 0.3s ease 0s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    text-align: center;
    background-color: #ffffff;
    margin-right: 6px;
    border-radius: 50%;

    &:hover {
      background-color: #f57c00;
    }
  }

  svg {
    font-size: 15px;
    color: #101010;
  }
`;
