import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Partners() {
  let settings = {
    dots: false,
    arrows: false,
    focusOnSelect: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const partners = [
    {
      id: 1,
      imageUrl: 'images/lafarge.png',
    },
    {
      id: 2,
      imageUrl: 'images/lagosgovt.png',
    },
    {
      id: 3,
      imageUrl: 'images/coleman.webp',
    },
    {
      id: 4,
      imageUrl: 'images/dangote.png',
    },
    {
      id: 5,
      imageUrl: 'images/ogunState.jpg',
    },
    {
      id: 6,
      imageUrl: 'images/christembassy.png',
    },
    {
      id: 7,
      imageUrl: 'images/Marian_Hotel.png',
    },
    {
      id: 8,
      imageUrl: 'images/wilmar.png',
    },
    {
      id: 9,
      imageUrl: 'images/bloom-tech.jpg',
    },
    {
      id: 10,
      imageUrl: 'images/unico.jpg',
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Carousel {...settings}>
          {partners.map(({ imageUrl, id }) => (
            <PartnerItem key={id}>
              <img src={imageUrl} alt="partners" />
            </PartnerItem>
          ))}
        </Carousel>
      </Container>
    </Wrapper>
  );
}

export default Partners;

const Wrapper = styled.div`
  padding: 0 0 50px;
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  /* @media (min-width: 1200px) {
    width: 1170px;
  } */
`;

const Carousel = styled(Slider)``;

const PartnerItem = styled.div`
  width: 165px !important;
  margin-right: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: 100px;
    border: 0;
  }
`;
